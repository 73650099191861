import React from "react";

import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import styled from 'styled-components';

function Publications() {
  return (
    <motion.div
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
        
      <Bullets>
        <h2>EMNLP 2021</h2>
        <ul>
          <li>
          Mirzakhalov, J., Babu, A., Kunafin, A., Wahab, A., Moydinboyev, B., Ivanova, S., Uzokova, M., Pulatova, S., Ataman, D., Kreutzer, J., Tyers, F., Firat, O., Licato, J. and Chellappan, S. (2021). <b>Evaluating Multiway Multilingual NMT in the Turkic Languages</b>. Long paper at the Workshop on Machine Translation (WMT) 2021. <a href="https://arxiv.org/abs/2109.06262">View on Arxiv</a>.
          </li>
          <li>
          Mirzakhalov, J., Babu, A., Ataman, D., Kariev, S., Tyers, F., Abduraufov, O., Hajili, M., Ivanova, S., Khaytbaev, A., Laverghetta Jr, A., Moydinboyev, B., Onal, E., Pulatova, S., Wahab, A., Firat, O. and Chellappan, S. (2021). <b>A Large-Scale Study of Machine Translation in the Turkic Languages</b>. Long paper at the main proceedings of EMNLP 2021.<a href="https://arxiv.org/abs/2109.04593">View on Arxiv</a>.
          </li>
        </ul>
        <br/>
        <h2>AfricaNLP 2021 (at EACL)</h2>
        <ul>
          <li>
          Caswell, I., Kreutzer, J., Wang, L., Wahab, A., Esch, D.V., Ulzii-Orshikh, N., Tapo, A.A., Subramani, N., Sokolov, A., Sikasote, C., Setyawan, M., Sarin, S., Samb, S., Sagot, B., Rivera, C., Rios Gonzales, A., Papadimitriou, I., Osei, S., Ortiz Suárez, P.J., Orife, I., Ogueji, K., Niyongabo, R.A., Nguyen, T.Q., Muller, M., Muller, A., Muhammad, S.H., Muhammad, N., Mnyakeni, A., Mirzakhalov, J., Matangira, T., Leong, C., Lawson, N., Kudugunta, S., Jernite, Y., Jenny, M., Firat, O., Dossou, B.F., Dlamini, S., Silva, N.D., Balli, S.C., Biderman, S.R., Battisti, A., Baruwa, A., Bapna, A., Baljekar, P.N., Azime, I.A., Awokoya, A., Ataman, D., Ahia, O., Ahia, O., Agrawal, S., and Adeyemi, M. (2021). <b>Quality at a Glance: An Audit of Web-Crawled Multilingual Datasets.</b> <a href="https://arxiv.org/abs/2103.12028">View on Arxiv</a>
          </li>
        </ul>
      </Bullets>
    </motion.div>
  );
}

const Bullets = styled.div`
    padding: 5rem 10rem;
    margin-left: 10px;
    color: black;
    min-height: 90vh;
    font-size: 2rem;
    h2 {
        font-size: 2rem;
    }
    li {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 50px;
        font-size: 1rem;
        font-family: 'Abel', sans-serif;
        font-weight: lighter;
        color: black
    }
    @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
`

export default Publications;
