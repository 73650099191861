import hacker from './assets/images/hacker.svg';
import girl from './assets/images/girl.svg';
import student from './assets/images/student.svg';
import anoop from './assets/people/anoop.jpeg'
import jim from './assets/people/jim.jpg'
import ahsan from './assets/people/ahsan.jpeg'
import mokhiyakhon from './assets/people/mokhiyakhon.jpg'
import sherzod from './assets/people/sherzod.jpg'
import shaxnoza from './assets/people/shaxnoza.jpg'
import abror from './assets/people/abror.jpeg'
import duygu from './assets/people/duygu.png'
import agrawal from './assets/people/agrawal.jpg'
import mammad from './assets/people/mammad.jpg'
import bekhzod from './assets/people/bekhzod.jpg'
import antonio from './assets/people/antonio.jpg'
import aigiz from './assets/people/aigiz.png'


export const TeamState = () => {
    return [
        {
            Name: 'Abror Khaytbayev',
            Role: 'Undergraduate Student @ University of South Florida',
            Img: abror,
            Link: ""
        },
        {
            Name: 'Aigiz Kunafin',
            Role: 'Machine Learning and AI @ Trustbit.tech',
            Img: aigiz,
            Link: "https://www.linkedin.com/in/aigizk/"
        },
        {
            Name: 'Ahsan Wahab',
            Role: 'Software Engineer @ Deloitte',
            Img: ahsan,
            Link: "https://www.linkedin.com/in/ahsan-wahab-6784bb161/"
        },

        {
            Name: 'Anoop Babu',
            Role: 'Graduate Student @ University of South Florida',
            Img: anoop,
            Link: "https://github.com/anoopbabu29"
        },
        {
            Name: 'Antonio Laverghetta Jr.',
            Role: 'Graduate Student @ University of South Florida',
            Img: antonio,
            Link: "https://www.linkedin.com/in/antoniolaverghettajr/"
        },
        {
            Name: 'Bekhzod Moydinboyev',
            Role: 'Undergraduate Student @ University of South Florida',
            Img: bekhzod,
            Link: ""
        },
        {
            Name: 'Duygu Ataman',
            Role: 'Post-doc @ the University of Zürich',
            Img: duygu,
            Link: "https://d-ataman.github.io/"
        },
        {
            Name: 'Esra Önal',
            Role: 'Graduate Student @ Indiana University',
            Img: girl,
            Link: ""
        },
        {
            Name: 'Francis Tyers',
            Role: 'Assistant Professor of Linguistics @ Indiana University',
            Img: hacker,
            Link: "https://scholar.google.fr/citations?user=o5HSM6cAAAAJ&hl=en"
        },
        {
            Name: 'Jamshidbek Mirzakhalov',
            Role: 'Graduate Student @ University of South Florida',
            Img: jim,
            Link: "http://mirzakhalov.me"
        },
        {
            Name: 'Mammad Hajili',
            Role: 'Graduate Student @ École Polytechnique Fédérale de Lausanne (EPFL)',
            Img: mammad,
            Link: "https://www.linkedin.com/in/mammadhajili/?originalSubdomain=ch"
        },
        {
            Name: 'Mokhiyakhon Uzoqova',
            Role: 'Undergraduate Student @ University of Uzbek Language and Literature',
            Img: mokhiyakhon,
            Link: ""
        },
        {
            Name: 'Orhan Firat',
            Role: 'Research Scientist @ Google Research',
            Img: hacker,
            Link: "https://scholar.google.com.tr/citations?hl=en&user=dLaR9lgAAAAJ&view_op=list_works&sortby=pubdate#"
        },
        {
            Name: 'Otabek Abduraufov',
            Role: 'Undergraduate Student @ University of South Florida',
            Img: hacker,
            Link: ""
        },
        {
            Name: 'Sardana Ivanova',
            Role: 'Graduate Student @ Helsinki University',
            Img: girl,
            Link: ""
        },
        {
            Name: 'Shahnoza Pulatova',
            Role: 'Undergraduate Student @ Namangan State University',
            Img: shaxnoza,
            Link: ""
        },
        {
            Name: 'Sherzod Kariev',
            Role: 'Graduate Student @ University of South Florida',
            Img: sherzod,
            Link: ""
        },
        {
            Name: 'Vishakha Agrawal',
            Role: 'Undergraduate Student @ Dayananda Sagar College, India',
            Img: agrawal,
            Link: 'https://www.linkedin.com/in/vishakha-a/'
        }
    ];
};

export const TranslatorsState = () => {
    return [
        {
            Name: 'Abror Khaytbayev',
            Role: 'Karakalpak, Uzbek',
            Img: abror,
            Link: ""
        },
        {
            Name: 'Alperen Cantez',
            Role: 'Turkish',
            Img: student,
            Link: ""
        },
        {
            Name: 'Bekhzod Moydinboyev',
            Role: 'Uzbek',
            Img: bekhzod,
            Link: ""
        },
        {
            Name: 'Fatima Maral',
            Role: 'Karachay-Balkar',
            Img: girl,
            Link: ""
        },
        {
            Name: 'Madina Zokirjonova',
            Role: 'Uzbek',
            Img: girl,
            Link: ""
        },
        {
            Name: 'Mammad Hajili',
            Role: 'Azerbaijani',
            Img: mammad,
            Link: "https://www.linkedin.com/in/mammadhajili/?originalSubdomain=ch"
        },
        {
            Name: 'Muhammad Haydarov',
            Role: 'Uzbek',
            Img: student,
            Link: ""
        },
        {
            Name: 'Shamil Guta',
            Role: 'Karachay-Balkar',
            Img: student,
            Link: ""
        },
        {
            Name: 'Vokhabov Erkinbek',
            Role: 'Uzbek',
            Img: student,
            Link: ""
        },
        {
            Name: 'Ziyodabonu Qobiljon qizi',
            Role: 'Qirg\'iz',
            Img: girl,
            Link: ""
        },
        
       
        


    ];
};